import './Information.css';

function Information({ title, message }) {
    return (
        <div>
            <p className='capitalize' style={{ marginBottom: '7px' }}>{title}</p>
            <p style={{ marginBottom: '20px' }}>{message}</p>
        </div>
    );
}

export default Information;