import CalendlyButton from '../CalendlyButton/CalendlyButton';
import './Pitch.css';

function Pitch({ isSmallScreen }) {
    return (
        <div className='pitch'>
            <span>
                <img src={require('../../img/sunset.jpg')} alt="sunset" className='scenery' />
            </span>
            <div className='pitch-content'>
                <h2>Je vous accompagne vers votre nouveau quotidien d'avocat indépendant</h2>
                <h3 style={{ marginTop: isSmallScreen ? '10px' : '35px' }}>Reprendre le contrôle | Être plus efficace | Gagner en sérénité</h3>
            </div>
            {!isSmallScreen && <div className='end'>
                <CalendlyButton color='blue' label="Réserver un appel avec moi" />
            </div>}

        </div>
    );
}

export default Pitch;