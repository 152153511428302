import CalendlyButton from '../CalendlyButton/CalendlyButton';
import './Banner.css';

function Banner({ isMediumScreen, isSmallScreen }) {
    return (
        <>{!isSmallScreen ?
            <div className='banner green'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    <img src={require('../../img/asm.jpg')} alt="Anne-Sophie Moumaneix" className='portrait' />
                    <p style={{ maxWidth: '100px', textAlign: 'center' }}>Anne-Sophie Moumaneix</p>
                </div>

                <div className='banner-content'>
                    <h2 style={{ marginTop: '35px' }}>Bienvenue chez la Fée du Cabinet</h2>
                    <h1 style={{ marginTop: '20px' }}>Conseil & Stratégie | Transformation Digitale | Management</h1>
                    <div style={{ marginTop: '35px', marginBottom: '7px' }}>
                        <CalendlyButton color='yellow' label="J'ai besoin de changement" />
                    </div>
                    <div style={{ marginBottom: isSmallScreen || isMediumScreen ? '10px' : '20px' }}>
                        <p style={{ color: 'black', fontSize: isMediumScreen || isSmallScreen ? '8px' : '12px' }}>Faisons connaissance ! C'est gratuit</p>
                    </div>

                </div>

                <img src={require('../../img/logo.png')} alt="La Fée du Cabinet" className='logo' />
            </div> :
            <div style={{ display: 'flex', flexDirection: "column" }} className='banner green'>
                <h2 style={{ marginTop: isSmallScreen ? '20px' : '35px' }}>Bienvenue chez la Fée du Cabinet</h2>

                <div className='banner green'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        <img src={require('../../img/asm.jpg')} alt="Anne-Sophie Moumaneix" className='portrait' />
                        <p style={{ maxWidth: '60px', textAlign: 'center', fontSize: '9px', marginBottom: '0.5vh' }}>Anne-Sophie Moumaneix</p>
                    </div>


                    <div className='banner-content'>
                        <h1 style={{ marginTop: '10px' }}>Conseil & Stratégie | Transformation Digitale | Management</h1>
                        <div style={{ marginTop: '15px', marginBottom: '7px' }}>
                            <CalendlyButton color='yellow' label="J'ai besoin de changement" />
                        </div>
                        <div style={{ marginBottom: isSmallScreen || isMediumScreen ? '10px' : '20px' }}>
                            <p style={{ color: 'black', fontSize: isSmallScreen ? '10px' : isMediumScreen ? '8px' : '12px' }}>Faisons connaissance ! C'est gratuit</p>
                        </div>

                    </div>

                    <img src={require('../../img/logo.png')} alt="La Fée du Cabinet" className='logo' />
                </div>
            </div>}</>
    );
}

export default Banner;