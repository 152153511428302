import CalendlyButton from '../CalendlyButton/CalendlyButton';
import PropositionBloc from '../PropositionBloc/PropositionBloc';
import './Propositions.css';

function Propositions({ isSmallScreen }) {
    return (
        <div className='propositions'>
            <div className='propositions-header' style={{ marginTop: isSmallScreen ? '20px' : '40px', marginBottom: isSmallScreen ? '10px' : '20px' }}>
                <h6>Ce que je propose</h6>
                <CalendlyButton color='blue' label="Réserver un appel avec moi" />
            </div>

            <div className='propositions-content'>
                <PropositionBloc title="Faire un bilan" message={
                    [<p>Prise de recul</p>,
                    <p>Comprendre les irritants</p>,
                    <p>Définir les objectifs</p>,
                    <p>Prioriser</p >,
                    <p>Définir le périmètre de ma mission</p >]
                } />
                <PropositionBloc title="Transformer" message={
                    [<p>On transforme</p>,
                    <p >On accumule des données</p>,
                    <p >On mesure les résultats</p>,
                    <p >On arbitre</p>,
                    <br className='br' />,
                    <p>et ce cycle se répète, on avance étape par étape</p>]
                } />
                <PropositionBloc title="Suivre" message={
                    [<p>Ensuite, on vérifie régulièrement</p>,
                    <ul ><li>L'équilibre est-il bon ?</li></ul>,
                    <ul ><li>Les objectifs ont-ils changé ?</li></ul>,
                    <ul ><li>Quel est le prochain ?</li></ul>
                    ]
                } />

            </div>

            <div style={{ width: 'fit-content', margin: 'auto', marginTop: isSmallScreen ? '30px' : '50px' }}>
                <CalendlyButton color='yellow' label="J'ai besoin de changement" />
            </div>
            <div className='links'>
                <a href="https://drive.google.com/file/d/12HSbuCCkoCvDjNiZHe8Ykp4rRhf0yLzm/view?usp=sharing" target="_blank"><span>CGV</span></a>
                <a href="https://www.linkedin.com/in/anne-sophie-moumaneix-86452925/" target="_blank" rel="noopener noreferrer">
                    <img src={require('../../img/linkedin.png')} style={{ width: '20px', height: '20px', marginLeft: "30px" }} alt="Linkedin logo" />
                </a>
            </div>
        </div>
    );
}

export default Propositions;