import { PopupButton } from 'react-calendly';
import './CalendlyButton.css';

function CalendlyButton({ color, label }) {
    return (
        <PopupButton className={`button ${color}`}
            text={label}
            url="https://calendly.com/lafeeducabinet/premiere-rencontre"
            rootElement={document.getElementById("root")}
        />
    );
}

export default CalendlyButton;