import './PropositionBloc.css';

function PropositionBloc({ title, message }) {
    return (
        <div className='proposition-bloc'>
            <p id='head' className='capitalize' style={{ marginBottom: '7px' }}>{title}</p>
            {message?.map((mes, index) => {
                return <div key={index}>{mes}</div>
            })}
        </div>
    );
}

export default PropositionBloc;