import ContextContent from "./ContexteContent/ContextContent";

function Context({ isMediumScreen, isSmallScreen }) {

    return (
        <div className='content'>
            <p style={{ marginBottom: isSmallScreen ? '7px' : isMediumScreen ? '10px' : '15px' }}>Ce que j’entends souvent en arrivant...</p>

            <ContextContent subtitle={<p>...au sujet de la <strong>Gestion documentaire / de la Digitalisation</strong> :</p>} content={[
                <i>Je croule sous le papier!</i>,
                <i key='2'>Je sais qu'il faudrait que je digitalise mais je n'ai pas le temps ...</i>,
                <i key='3'>Passer aux scans OK, mais PC ou Cloud ? J'ai besoin de trop de stockage ! Quels sont les risques ?</i>,
                <i key='4'>Intégrer les mails à mes dossiers est une tâche quotidienne harassante !</i>
            ]} isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} />

            <ContextContent subtitle={<p>...au sujet de la <strong>Facturation</strong> :</p>} content={[
                <i>Je vais devoir passer aux factures électroniques.</i>,
                <i>Je navigue à vue sur les factures / les impayés</i>,
                <i >Je n'ai aucun indicateur financier de mon cabinet.</i>,
                <i>Dois - je augmenter mes prix ?</i>
            ]} isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} />


            <ContextContent subtitle={<p>...au sujet de la recherche de <strong>nouveaux clients</strong> :</p>} content={[
                <i>Je suis constamment à la recherche de nouveaux clients.Ça m'éloigne de mon métier...</i>
            ]} isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} />

            <ContextContent subtitle={<p>...au sujet de la mise en place d'une <strong>collaboration</strong> :</p>} content={[
                <i> J'ai toujours travaillé seul.</i>,
                <i > Pas facile de gérer quelqu'un ! Je n'ai jamais appris</i>
            ]} isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} />
        </div>
    );
}

export default Context;