import CalendlyButton from '../CalendlyButton/CalendlyButton';
import './Separator.css';

function Separator() {
    return (
        <div className='separator green'>
            <p>Vous vous reconnaissez sur un ou plusieurs de ces sujets ?</p>
            <CalendlyButton color='blue' label="Réserver un appel avec moi" />
        </div>);
}

export default Separator;