import { useEffect, useState } from 'react';
import './App.css';
import Banner from './components/Banner/Banner';
import Context from './components/Context/Context';
import Informations from './components/Informations/Informations';
import Pitch from './components/Pitch/Pitch';
import Propositions from './components/Propositions/Propositions';
import Separator from './components/Separator/Separator';

function App() {
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMediumScreen(screenWidth < 900 && screenWidth >= 600);
      setIsSmallScreen(screenWidth < 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div style={{ fontFamily: 'Bahnschrift Light' }}>
      <div style={{ backgroundColor: "white" }}>

        <Banner isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} />

        <Pitch isSmallScreen={isSmallScreen} />

        <div style={{ marginTop: isSmallScreen ? '20px' : '40px' }}><Context isMediumScreen={isMediumScreen} isSmallScreen={isSmallScreen} /></div>

        <div style={{ marginTop: isSmallScreen ? '20px' : '40px' }}><Separator /></div>

        <div style={{ marginTop: isSmallScreen ? '20px' : '40px', marginBottom: isSmallScreen ? '20px' : '40px' }}><Informations isSmallScreen={isSmallScreen} /></div>

        <div className='footer' style={{ paddingBottom: isSmallScreen ? '30px' : '50px' }}>
          <Propositions isSmallScreen={isSmallScreen} />
        </div>
      </div>
    </div >
  );
}

export default App;
