import Information from '../Information/Information';
import InformationBloc from '../InformationBloc/InformationBloc';
import './Informations.css';

function Informations({ isSmallScreen }) {
    return (
        <div className='informations'>
            <h6 style={{ marginBottom: '20px' }}>Qui suis-je pour répondre ?</h6>
            <div className='informations-content'>
                <div>
                    <Information title="Une gestionnaire stratégique" message="Issue de grands groupes internationaux, j'ai dirigé une entreprise pendant plus de 6 ans. Les chiffres et les indicateurs sont mes amis !" />
                    <Information title="Une manager avisée" message="Manager confirmée, j'ai géré avec succès plusieurs équipes, dans des contextes différents, dans de petites et de grandes structures." />
                    <Information title="Une experte en tranformation digitale" message="Ingénieure avec plus de 25 ans d'expérience, je viens du monde de l'édition du logiciel. C'est mon domaine d'expertise." />
                </div>

                <div style={{ marginLeft: isSmallScreen ? '0vw' : '5vw' }}>
                    <InformationBloc />
                </div>
            </div>
        </div >
    );
}

export default Informations;