function ContextContent({ subtitle, content, isMediumScreen, isSmallScreen }) {
    return (
        <div>
            <div style={{ marginLeft: isSmallScreen ? '5vw' : '10vw', marginBottom: isSmallScreen ? '3px' : isMediumScreen ? '10px' : '15px' }}>{subtitle}</div>
            {content?.map((cont, index) => {
                return <div key={index} style={{ marginLeft: isSmallScreen ? '10vw' : '20vw', marginBottom: index == content.length - 1 ? '15px' : '0px ' }}>{cont}<br /></div>;
            })}
        </div>
    );

}

export default ContextContent;