import './InformationBloc.css';

function InformationBloc() {
    return (
        <div className='information-bloc green'>
            <h2 style={{ marginBottom: '15px', textAlign: 'start' }}> Une approche personnalisée au service de vos attentes</h2>
            <p style={{ marginBottom: '10px' }}>Comme des gestes de gymnastique :<br />
                Comprendre, puis transformer, mesurer, prioriser et on recommence !</p>
            <p>Chaque cycle améliore, développe votre cabinet, élimine les irritants quotidiens.</p>
        </div>
    );
}

export default InformationBloc;